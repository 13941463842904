export default {
  data() {
    return {
      dialogIsShow: false, //弹出层
      checkData: [
        //全部列
        '配置时间',
        '摄像头编号',
        '盒子编号',
        '商户名称',
        '门店名称',
        '推流状态',
        '操作',
        'MAC-ID',
        '录播开关',
        '摄像头状态',
      ],
      checkAll: false, //全选
      checkDefult: [], //
      setCheckTrue: [], //选中的
      isIndeterminate: true,
      setCheck: [],
      tableColumnData: [],
      isIndeterminateBaseInfo: true,
      checkAllBaseInfo: false,
      checkedBaseInfo: ['mac', 'cameraId', 'cameraName', 'customerName', 'shopName', 'shopId', 'boxMac', 'boxPort'],
      baseInfoData: [
        {
          label: 'MacID',
          key: 'mac',
          width: 150,
          sortable: 'custom',
        },
        {
          label: 'Camera ID',
          key: 'cameraId',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '摄像头名称',
          key: 'cameraName',
          width: 120,
          sortable: 'custom',
        },
        {
          label: '盒子MacID',
          key: 'boxMac',
          width: 120,
          sortable: 'custom',
        },
        {
          label: '盒子端口号',
          key: 'boxPort',
          width: 110,
          sortable: 'custom',
        },
        {
          label: '客户名称',
          key: 'customerName',
          width: 120,
          sortable: 'custom',
        },
        {
          label: 'CID',
          key: 'customerId',
          width: 120,
          sortable: 'custom',
        },
        {
          label: '门店名称',
          key: 'shopName',
          width: 180,
          sortable: 'custom',
        },
        {
          label: 'ShopID',
          key: 'shopId',
          width: 120,
          sortable: 'custom',
        },
      ],
      isIndeterminateInstallInfo: false,
      checkAllInstallInfo: false,
      checkedInstallInfo: [],
      installInfoData: [
        {
          label: '安装位置',
          key: 'installLocation',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '安装高度',
          key: 'installHeight',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '安装角度',
          key: 'installAngle',
          width: 100,
          sortable: 'custom',
        },
      ],
      isIndeterminateHardwareInfo: false,
      checkAllHardwareInfo: false,
      checkedHardwareInfo: [],
      hardwareInfoData: [
        {
          label: '品牌',
          key: 'brand',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '型号',
          key: 'model',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '摄像头焦距',
          key: 'focalLength',
          width: 100,
          sortable: 'custom',
        },
      ],
      isIndeterminateWorkInfo: true,
      checkAllWorkInfo: false,
      checkedWorkInfo: ['dataRate', 'onlineStatus'],
      workInfoData: [
        {
          label: '计算码流',
          key: 'dataRate',
          width: 120,
          sortable: 'custom',
        },
        {
          label: '入库时间',
          key: 'wareHousingTime',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '绑定时间',
          key: 'bindTime',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '在线状态',
          key: 'onlineStatus',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '最后心跳时间',
          key: 'lastHeartBeatTime',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '业务程序',
          key: 'runningProcess',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '区域配置',
          key: 'areaConfig',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '是否上传视频',
          key: 'uploadVideoStatus',
          width: 120,
          sortable: 'custom',
        },
        {
          label: '回放方式',
          key: 'boxReplay',
          width: 120,
          sortable: 'custom',
        },
      ],
      isIndeterminateCameraConfig: false,
      checkAllCameraConfig: false,
      checkedCameraConfig: [],
      cameraConfigData: [
        {
          label: '分辨率(主)',
          key: 'resolution',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '图像质量(主)',
          key: 'videoQuality',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '视频编码(主)',
          key: 'encode',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '关键帧间隔(主)',
          key: 'keyFrameInterval',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '帧率(主)',
          key: 'frameRate',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '码流上限(主)',
          key: 'dataRateUpperLimit',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '码流类型(主)',
          key: 'fpsType',
          width: 150,
          sortable: 'custom',
        },
      ],
      isIndeterminateCameraParams: false,
      checkAllCameraParams: false,
      checkedCameraParams: [],
      cameraParamsData: [
        {
          label: '快门时间',
          key: 'shutterTime',
          width: 120,
          sortable: 'custom',
        },
        {
          label: '宽动态',
          key: 'wdrStatus',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '宽动态等级',
          key: 'wdrLevel',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '网络配置',
          key: 'netConfig',
          width: 120,
          sortable: 'custom',
        },
        {
          label: '时间配置',
          key: 'timeConfig',
          width: 120,
          sortable: 'custom',
        },
      ],
      isIndeterminateStreamConfig: false,
      checkAllStreamConfig: false,
      checkedStreamConfig: [],
      streamConfigData: [
        {
          label: '分辨率(次)',
          key: 'resolution2',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '图像质量(次)',
          key: 'videoQuality2',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '视频编码(次)',
          key: 'encode2',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '关键帧间隔(次)',
          key: 'keyFrameInterval2',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '帧率(次)',
          key: 'frameRate2',
          width: 100,
          sortable: 'custom',
        },
        {
          label: '码流上限(次)',
          key: 'dataRateUpperLimit2',
          width: 150,
          sortable: 'custom',
        },
        {
          label: '码流类型(次)',
          key: 'fpsType2',
          width: 150,
          sortable: 'custom',
        },
      ],
    }
  },
  methods: {
    // 基本信息
    handleCheckAllBaseInfoChange(val) {
      const baseInfoData = this.baseInfoData.map((item) => item.key)
      this.checkedBaseInfo = val ? baseInfoData : ['mac']
      this.isIndeterminateBaseInfo =
        this.checkedBaseInfo.length > 0 && this.checkedBaseInfo.length < baseInfoData.length
    },
    handleBaseInfoChange(value) {
      let checkedCount = value.length
      this.checkAllBaseInfo = checkedCount === this.baseInfoData.length
      this.isIndeterminateBaseInfo = checkedCount > 0 && checkedCount < this.baseInfoData.length
    },
    // 安装信息
    handleCheckAllInstallInfoChange(val) {
      this.checkedInstallInfo = val ? this.installInfoData.map((item) => item.key) : []
      this.isIndeterminateInstallInfo = false
    },
    handleInstallInfoChange(value) {
      let checkedCount = value.length
      this.checkAllInstallInfo = checkedCount === this.installInfoData.length
      this.isIndeterminateInstallInfo = checkedCount > 0 && checkedCount < this.installInfoData.length
    },
    // 硬件信息
    handleCheckAllHardwareInfoChange(val) {
      this.checkedHardwareInfo = val ? this.hardwareInfoData.map((item) => item.key) : []
      this.isIndeterminateHardwareInfo = false
    },
    handleHardwareInfoChange(value) {
      let checkedCount = value.length
      this.checkAllHardwareInfo = checkedCount === this.hardwareInfoData.length
      this.isIndeterminateHardwareInfo = checkedCount > 0 && checkedCount < this.hardwareInfoData.length
    },
    // 运行信息
    handleCheckAllWorkInfoChange(val) {
      this.checkedWorkInfo = val ? this.workInfoData.map((item) => item.key) : ['dataRate']
      this.isIndeterminateWorkInfo =
        this.checkedWorkInfo.length > 0 && this.checkedWorkInfo.length < this.workInfoData.length
    },
    handleWorkInfoChange(value) {
      let checkedCount = value.length
      this.checkAllWorkInfo = checkedCount === this.workInfoData.length
      this.isIndeterminateWorkInfo = checkedCount > 0 && checkedCount < this.workInfoData.length
    },
    // 摄像头配置
    handleCheckAllCameraConfigChange(val) {
      this.checkedCameraConfig = val ? this.cameraConfigData.map((item) => item.key) : []
      this.isIndeterminateCameraConfig = false
    },
    handleCameraConfigChange(value) {
      let checkedCount = value.length
      this.checkAllCameraConfig = checkedCount === this.cameraConfigData.length
      this.isIndeterminateCameraConfig = checkedCount > 0 && checkedCount < this.cameraConfigData.length
    },
    // 摄像头参数
    handleCheckAllCameraParamsChange(val) {
      this.checkedCameraParams = val ? this.cameraParamsData.map((item) => item.key) : []
      this.isIndeterminateCameraParams = false
    },
    handleCameraParamsChange(value) {
      let checkedCount = value.length
      this.checkAllCameraParams = checkedCount === this.cameraParamsData.length
      this.isIndeterminateCameraParams = checkedCount > 0 && checkedCount < this.cameraParamsData.length
    },
    // 次码流配置
    handleCheckAllStreamConfigChange(val) {
      this.checkedStreamConfig = val ? this.streamConfigData.map((item) => item.key) : []
      this.isIndeterminateStreamConfig = false
    },
    handleStreamConfigChange(val) {
      let checkedCount = val.length
      this.checkAllStreamConfig = checkedCount === this.streamConfigData.length
      this.isIndeterminateStreamConfig = checkedCount > 0 && checkedCount < this.streamConfigData.length
    },
    // 显示设置自定义列
    setTrue() {
      this.dialogIsShow = true
    },
    //恢复默认
    setDefult() {
      // 基本信息
      this.checkedBaseInfo = [
        'mac',
        'cameraId',
        'cameraName',
        'customerName',
        'shopName',
        'shopId',
        'boxMac',
        'boxPort',
      ]
      this.isIndeterminateBaseInfo = true
      // 运行信息
      this.checkedWorkInfo = ['onlineStatus', 'dataRate']
      this.isIndeterminateWorkInfo = true
      // 安装信息
      this.checkAllInstallInfo = false
      this.checkedInstallInfo = []
      this.isIndeterminateInstallInfo = false
      // 硬件信息
      this.checkedHardwareInfo = []
      this.isIndeterminateHardwareInfo = false
      this.checkAllHardwareInfo = false
      // 摄像头参数
      this.checkedCameraParams = []
      this.checkAllCameraParams = false
      this.isIndeterminateCameraParams = false
      // 主码流配置
      this.checkedCameraConfig = []
      this.isIndeterminateCameraConfig = false
      this.checkAllCameraConfig = false
      // 次码流配置
      this.checkedStreamConfig = []
      this.isIndeterminateStreamConfig = false
      this.checkAllStreamConfig = false
    },
    handleCheckAllChange(val) {
      //全选
      const checkedKey = this.copyCheckedData.map((item) => item.key)
      this.checkedCities = val ? checkedKey : ['macid', 'operation']
      this.isIndeterminate = !val
    },
    handleCheckedCitiesChange(value) {
      // 勾选全选按钮
      let checkedCount = value.length
      this.checkAll = checkedCount === this.copyCheckedData.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.copyCheckedData.length
    },
    getCheckTrue() {
      //确认按钮
      this.dialogIsShow = false
    },
    // 动态改变表格列
    getTableList() {
      const selectBaseInfo = this.baseInfoData.filter((item) => this.checkedBaseInfo.includes(item.key))
      const selectInstalledInfo = this.installInfoData.filter((item) => this.checkedInstallInfo.includes(item.key))
      const selectHardwareInfo = this.hardwareInfoData.filter((item) => this.checkedHardwareInfo.includes(item.key))
      const selectWorkInfo = this.workInfoData.filter((item) => this.checkedWorkInfo.includes(item.key))
      const selectCameraConfig = this.cameraConfigData.filter((item) => this.checkedCameraConfig.includes(item.key))
      const selectCameraParams = this.cameraParamsData.filter((item) => this.checkedCameraParams.includes(item.key))
      const selectStreamConfig = this.streamConfigData.filter((item) => this.checkedStreamConfig.includes(item.key))
      this.tableColumnData = [
        ...selectBaseInfo,
        ...selectInstalledInfo,
        ...selectHardwareInfo,
        ...selectWorkInfo,
        ...selectCameraConfig,
        ...selectCameraParams,
        ...selectStreamConfig,
      ]
      this.dialogIsShow = false
      this.overviewInit()
    },
    dialogCancel() {
      //取消
      // this.dialogIsShow = false;
      // this.checkedCities = this.setCheckTrue;
      this.dialogIsShow = false
    },
  },
  watch: {
    dialogIsShow(val) {
      if (val) {
        const tableColumnData = this.tableColumnData.map((item) => item.key)
        this.checkedBaseInfo = this.baseInfoData
          .filter((item) => tableColumnData.includes(item.key))
          .map((item) => item.key)
        this.checkedInstallInfo = this.installInfoData
          .filter((item) => tableColumnData.includes(item.key))
          .map((item) => item.key)
        this.checkedHardwareInfo = this.hardwareInfoData
          .filter((item) => tableColumnData.includes(item.key))
          .map((item) => item.key)
        this.checkedWorkInfo = this.workInfoData
          .filter((item) => tableColumnData.includes(item.key))
          .map((item) => item.key)
        this.checkedCameraConfig = this.cameraConfigData
          .filter((item) => tableColumnData.includes(item.key))
          .map((item) => item.key)
        this.handleBaseInfoChange(this.checkedBaseInfo)
        this.handleInstallInfoChange(this.checkedInstallInfo)
        this.handleHardwareInfoChange(this.checkedHardwareInfo)
        this.handleWorkInfoChange(this.checkedWorkInfo)
        this.handleCameraConfigChange(this.checkedCameraConfig)
      }
    },
  },
  computed: {},
  mounted() {
    // this.checkDefult = this.checkData
    // this.setCheckTrue = this.checkedCities
    this.setCheck = this.checkedCities
    this.getCheckTrue()
    // let customCoulumn = JSON.parse(localStorage.getItem('customCoulumn'))
    // setTimeout(() => {
    //     this.getTableList()
    // }, 1000)
    this.$nextTick(() => {
      document.addEventListener('click', (e) => {
        if(!this.$refs.customDialog) return
        if (!this.$refs.customDialog.contains(e.target)) {
          this.dialogIsShow = false
        }
      })
    })
    
    // window.onbeforeunload = () => {
    //   const obj = {
    //       checkedBaseInfo: this.checkedBaseInfo
    //   }
    //   console.log(obj)
    //   localStorage.setItem('customCoulumn', JSON.stringify(obj))
    // }
  },
}
